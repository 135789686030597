<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Menu gestione Quotazioni</span>
            <br>
            Clicca su QUESTIONARIO ASSUNTIVO per fare i controlli e mettere la quotazione in lavorazione.
        </div>
        <br><br>

        <div class="row justify-center">

            <div class="col-md-4 col-12 q-pr-md" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{getStato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Telefono Ufficio #1:</q-item-label>
                            <q-item-label caption>{{dati_ufficio.numero_telefono1}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Telefono Ufficio #2:</q-item-label>
                            <q-item-label caption>{{dati_ufficio.numero_telefono2}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Email Ufficio #2:</q-item-label>
                            <q-item-label caption>{{dati_ufficio.email}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-md-6 col-12">
                <fieldset style="width: 100%;" class="q-mb-md">
                    <legend class="q-pr-md q-pl-md"> Informazioni </legend>
                    <br>
                    <QQButton :disabled="idQuestionarioDisabled" label="Questionario assuntivo" color="blue-grey" icon="mdi-card-account-details-outline" size="md" @click.native="onVisualizzaQuestionarioAssuntivo" />
                    <QQButton label="Problemi segnalati" color="blue-grey" icon="mdi-flag-outline" size="md" @click.native="onVisualizzaProblemiSegnalati" />
                    <QQButton label="Registro movimenti" color="blue-grey" icon="mdi-grid-off" size="md" @click.native="onVisualizzaRegistroMovimenti" />
                    <QQButton label="Documenti allegati" color="blue-grey" icon="mdi-briefcase-upload-outline" size="md" @click.native="onVisualizzaElencoDocumenti" />
                </fieldset>

                <fieldset class="q-mb-md" style="width: 100%;">
                    <legend class="q-pr-md q-pl-md"> Procedure </legend>
                    <br>
                    <QQButton :disabled="isDefinisciDisabled" label="Definisci Quotazione" color="blue-grey" icon="mdi-grid-off" size="md" @click.native="onDefinisciQuotazione" />
                    <QQButton :disabled="isRifiutaDisabled" label="Rifiuta Quotazione" color="blue-grey" icon="mdi-trash-can-outline" size="md" @click.native="onRifiutaQuotazione" />
                    <QQButton :disabled="isAnnullaDisabled" label="Annulla Quotazione" color="blue-grey" icon="mdi-close-outline" size="md" @click.native="onAnnullaQuotazione" />

                    <br>
                <!--    <QQButton disabled label="Metti in Lavorazione" color="blue-grey" icon="mdi-hammer-wrench" size="md" @click.native="onMettiInLavorazione" /> //-->
                    <QQButton :disabled="isAllegaDocumentiDisabled" label="Allega documenti" color="blue-grey" icon="mdi-lan-pending" size="md" @click.native="onAllegaDocumenti" />
                    <QQButton :disabled="isInviaSegnalazioneDisabled" label="Invia segnalazione" color="blue-grey" icon="mdi-lead-pencil" size="md" @click.native="onInviaSegnalazione" />
                    <QQButton :disabled="isInserisciAnnotazioneDisabled" label="Inserisci annotazione" color="blue-grey" icon="mdi-newspaper-variant-outline" size="md" @click.native="onInserisciAnnotazione" />
                </fieldset>
            </div>

        </div>


        <br><br><hr>
        <div class="col-md-4" align="center">
            <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                @click.native="onClickIndietro()"
            />
        </div>


        <br><br><br><br><br><br>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy.js";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";
    //import store from "@/store";

    export default {
        name: "MenuGestioneQuotazioni",
        data() {
            return {
                quotazione: {},
                dati_ufficio: {}
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo.formPreventivo
            }),
            getStato() {
                if (commonLib.isEmpty(this.quotazione)) return "";

                return this.quotazione.stato.replaceAll("_"," ");
            },
            isButtonDisabled() {
                //console.log("stato:",this.quotazione.stato);
                if (this.quotazione.stato === "RIFIUTATA") return true;

                return false;
            },
            isQuestionarioDisabled() {
                switch(this.quotazione.stato) {
                    case "RIFIUTATA":
                    case "DEFINITA":
                    case "IN_LAVORAZIONE": return true;
                }

                return false;
            },
            isAnnullaDisabled() {
                if (!policy.isSede()) return true;
                if (this.quotazione.stato === "DEFINITA") return true;

                return false;
            },
            idQuestionarioDisabled() {
                if (!policy.isSede()) return true;

                return false;
            },
            isDefinisciDisabled() {
                if (this.quotazione.stato === "IN_LAVORAZIONE") return false;

                return true;
            },
            isAllegaDocumentiDisabled() {
                //if(this.quotazione.stato !== "IN_LAVORAZIONE") return true;

                return false;
            },
            isInserisciAnnotazioneDisabled() {
                if (!policy.isSede()) return true;
                switch(this.quotazione.stato) {
                    case "RIFIUTATA":
                    case "DEFINITA": return true;
                }

                return false;
            },
            isInviaSegnalazioneDisabled() {
                return this.quotazione.stato === "DEFINITA";
            },
            isRifiutaDisabled() {
                if (!policy.isSede()) return true;
                return this.quotazione.stato === "DEFINITA";
            }
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                MettiInLavorazione: "gestioneQuotazioni/MettiInLavorazione",
                fetchDatiUfficioDaPreventivo: "formPreventivo/fetchDatiUfficioDaPreventivo"
                //fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo",
                //AnnullaQuotazione: "gestioneQuotazioni/AnnullaQuotazione"
            }),
            onClickIndietro() {
                this.$router.push({name : "Quotazioni"});
            },
            onVisualizzaQuestionarioAssuntivo() {
                this.$router.push({name : "Quotazioni.VisualizzaQuestionarioAssuntivo"});
            },
            onVisualizzaRegistroMovimenti() {
                this.$router.push({name : "Quotazioni.RegistroMovimentiPreventivo"});
            },
            onInserisciAnnotazione() {
                this.$router.push({name : "Quotazioni.RegistraAnnotazione"});
            },
            onRifiutaQuotazione() {
                this.$router.push({name : "Quotazioni.RifiutaQuotazione"});
            },
            onDefinisciQuotazione() {
                this.$router.push({name : "Quotazioni.DefinisciQuotazione"});
            },
            onMettiInLavorazione() {
                this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Metto la quotazione in stato LAVORAZIONE?',
                        cancel: {
                        focus: true,
                        flat: false,
                        label: "NO",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, procedi"
                    },
                    }).onOk(async () => {

                        await this.MettiInLavorazione(
                            {
                                guid_preventivo: this.quotazione.guid_preventivo,
                                id_quotazione: this.quotazione.idquotazione
                            }
                        );

                        this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
                    }).onCancel(() => {

                    }).onDismiss(() => {

                    })
            },
            onInviaSegnalazione() {
                this.$router.push({name : "Quotazioni.InviaSegnalazione"});
            },
            onVisualizzaProblemiSegnalati() {
                this.$router.push({name : "Quotazioni.ProblemiSegnalati"});
            },
            onVisualizzaElencoDocumenti() {
                this.$router.push({name : "Quotazioni.ElencoDocumenti"});
            },
            onAnnullaQuotazione() {
                if (this.quotazione.stato === "NUOVA") {
                    this.$router.push({name : "Quotazioni.AnnullaQuotazione"});
                }
            },
            onAllegaDocumenti() {
                this.$router.push({name : "Quotazioni.AllegaDocumenti"});
            },
            inCostruzione() {
                alert("IN COSTRUZIONE");
            }
        },
    //    async activated() {
    //        this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
    //        //await this.fetchDatiPreventivo(this.quotazione.guid_preventivo);
    //    },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            //await this.fetchDatiPreventivo(this.quotazione.guid_preventivo);
            this.dati_ufficio = await this.fetchDatiUfficioDaPreventivo(this.quotazione.guid_preventivo);
        }
    }
</script>
